<template>
<div>
    <Terms />
</div>
</template>

<script>
import Terms from '../../components/dashboard/helpSupport/TermsConditions.vue'
export default {
    components: {
        Terms
    }
}
</script>

<style>
    