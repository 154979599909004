<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" v-if="showNavbar"/>
        <div slot="content" :class="{'neg-margin': !showNavbar}">
            <div class="container">
                <div class="div-container mb-2 pt-4">
                    <div class="info-block">
                        <div class="num-block">
                            <div class="num-case fw-600">
                                <h3>1</h3>
                            </div>
                        </div>
                        <div class="detail-block">
                            <h4>{{tran.platform}}</h4>
                            <p>{{tran.platform_detail}}</p>
                        </div>
                        <div class="logo-block">
                            <img src="../../../assets/images/req.png" height="30">
                    </div>
                        </div>

                    </div>
                    <div class="div-container mb-2">
                        <div class="info-block">
                            <div class="num-block">
                                <div class="num-case fw-600">
                                    <h3>2</h3>
                                </div>
                            </div>
                            <div class="detail-block">
                                <h4>{{tran.user_organiz}}</h4>
                                <p>{{tran.user_org_detail}}</p>
                            </div>
                            <div class="logo-block">
                                <img src="../../../assets/images/req.png" height="30">
                                 </div>
                            </div>

                        </div>
                        <div class="div-container mb-2">
                            <div class="info-block">
                                <div class="num-block">
                                    <div class="num-case fw-600">
                                        <h3>3</h3>
                                    </div>
                                </div>
                                <div class="detail-block">

                                    <h4>{{tran.use_of_info}}</h4>
                                    <h6 class="pb-2 pt-2">

                                        {{tran.service_prod}}
                                    </h6>
                                    <p>{{tran.user_org_detail}}</p>

                                    <hr style="width:30%">

                                    <h6 class="pb-2 pt-2">

                                        {{tran.info_cont}}
                                    </h6>
                                    <p>{{tran.info_contain_detail}}</p>
                                    <hr style="width:30%">

                                    <h6 class="pb-2 pt-2">

                                        {{tran.security}}
                                    </h6>

                                    <p class="pb-2">{{tran.security_a}}</p>
                                    <p class="pb-2">{{tran.security_b}}</p>
                                    <p class="pb-2">{{tran.security_c}}</p>
                                    <p class="pb-2">{{tran.security_d}}</p>

                                </div>
                                <div class="logo-block">
                                    <img src="../../../assets/images/req.png" height="30">
                                </div>
                                </div>

                            </div>
                            <div class="div-container mb-2">
                                <div class="info-block">
                                    <div class="num-block">
                                        <div class="num-case fw-600">
                                            <h3>4</h3>
                                        </div>
                                    </div>
                                    <div class="detail-block">
                                        <h4>{{tran.disclaimer}}</h4>
                                        <p class="pt-2">{{tran.disclaimer_detail_a}}</p>
                                        <p class="pt-2">{{tran.disclaimer_detail_b}}</p>
                                        <p class="pt-2">{{tran.disclaimer_detail_c}}</p>
                                        <p class="pt-2">{{tran.disclaimer_detail_d}}</p>
                                    </div>
                                    <div class="logo-block">
                                        <img src="../../../assets/images/req.png" height="30">
                                    </div>
                                    </div>

                                </div>
                            <div class="div-container mb-2">
                                <div class="info-block">
                                    <div class="num-block">
                                        <div class="num-case fw-600">
                                            <h3>5</h3>
                                        </div>
                                    </div>
                                    <div class="detail-block">
                                        <h4>{{tran.the_req}}</h4>
                                        <p class="pt-2">{{tran.the_req_services}}</p>
                                        <p class="pt-2">{{tran.the_req_services_detail}}</p>
                                        <p class="pt-2">{{tran.the_req_ser_a}}</p>
                                        <p class="pt-2">{{tran.the_req_ser_b}}</p>
                                        <p class="pt-2">{{tran.the_req_ser_c}}</p>

                                        <hr style="width:30%">

                                        <h6 class="pb-2 pt-2">

                                            {{tran.transmitt}}
                                        </h6>
                                        <p class="pb-2">{{tran.transmitt_detail}}</p>
                                        <hr style="width:30%">
                                        <h6 class="pb-2 pt-2">

                                            {{tran.downloading}}
                                        </h6>
                                        <p class="pb-2">{{tran.downloading_detail}}</p>
                                    </div>
                                    <div class="logo-block">
                                        <img src="../../../assets/images/req.png" height="30">
                    </div>
                                    </div>

                                </div>
                                <div class="div-container mb-2">
                                    <div class="info-block">
                                        <div class="num-block">
                                            <div class="num-case fw-600">
                                                <h3>6</h3>
                                            </div>
                                        </div>
                                        <div class="detail-block">
                                            <h4>{{tran.desclaimer_endorse}}</h4>
                                            <p class="pt-2">{{tran.desclaimer_endorse_a}}</p>
                                            <p class="pt-2">{{tran.desclaimer_endorse_b}}</p>
                                            <p class="pt-2">{{tran.desclaimer_endorse_c}}</p>
                                            <p class="pt-2">{{tran.desclaimer_endorse_d}}</p>

                                        </div>
                                        <div class="logo-block">
                                            <img src="../../../assets/images/req.png" height="30">
                    </div>
                                        </div>

                                    </div>
                                    <div class="div-container mb-2">
                                        <div class="info-block">
                                            <div class="num-block">
                                                <div class="num-case fw-600">
                                                    <h3>7</h3>
                                                </div>
                                            </div>
                                            <div class="detail-block">
                                                <h4>{{tran.copyright}}</h4>
                                                <p class="pt-2">{{tran.copyright_a}}</p>
                                                <p class="pt-2">{{tran.copyright_b}}</p>

                                            </div>
                                            <div class="logo-block">
                                                <img src="../../../assets/images/req.png" height="30">
                                             </div>
                                            </div>

                                        </div>
                                        <div class="div-container mb-2">
                                            <div class="info-block">
                                                <div class="num-block">
                                                    <div class="num-case fw-600">
                                                        <h3>8</h3>
                                                    </div>
                                                </div>
                                                <div class="detail-block">
                                                    <h4>{{tran.severability}}</h4>
                                                    <p class="pt-2">{{tran.severability_details}}</p>

                                                </div>
                                                <div class="logo-block">
                                                    <img src="../../../assets/images/req.png" height="30">
                                                  </div>
                                                </div>

                                            </div>
                                        <div class="div-container mb-2">
                                            <div class="info-block">
                                                <div class="num-block">
                                                    <div class="num-case fw-600">
                                                        <h3>9</h3>
                                                    </div>
                                                </div>
                                                <div class="detail-block">
                                                    <h4>{{tran.no_waiver}}</h4>
                                                    <p class="pt-2">{{tran.no_waiver_details}}</p>

                                                </div>
                                                <div class="logo-block">
                                                    <img src="../../../assets/images/req.png" height="30">
                                                  </div>
                                                </div>

                                            </div>
                                        <div class="div-container mb-2">
                                            <div class="info-block">
                                                <div class="num-block">
                                                    <div class="num-case fw-600">
                                                        <h3>10</h3>
                                                    </div>
                                                </div>
                                                <div class="detail-block">
                                                    <h4>{{tran.governing}}</h4>
                                                    <p class="pt-2">{{tran.governing_details}}</p>

                                                </div>
                                                <div class="logo-block">
                                                    <img src="../../../assets/images/req.png" height="30">
                                                  </div>
                                                </div>

                                            </div>
                                              <div class="div-container mb-2">
                                            <div class="info-block">
                                                <div class="num-block">
                                                    <div class="num-case fw-600">
                                                        <h3>11</h3>
                                                    </div>
                                                </div>
                                                <div class="detail-block">
                                                    <h4>{{tran.alert_noti}}</h4>
                                                    <p class="pt-2">{{tran.alert_noti_details}}</p>

                                                </div>
                                                <div class="logo-block">
                                                    <img src="../../../assets/images/req.png" height="30">
                                                  </div>
                                                </div>

                                            </div>
                                              <div class="div-container mb-2">
                                            <div class="info-block">
                                                <div class="num-block">
                                                    <div class="num-case fw-600">
                                                        <h3>12</h3>
                                                    </div>
                                                </div>
                                                <div class="detail-block">
                                                    <h4>{{tran.your_access}}</h4>
                                                    <p class="pt-2">{{tran.your_access_detail}}</p>

                                                </div>
                                                <div class="logo-block">
                                                    <img src="../../../assets/images/req.png" height="30">
                                                  </div>
                                                </div>

                                            </div>

                                                          <div class="div-container mb-2">
                                        <div class="info-block">
                                            <div class="num-block">
                                                <div class="num-case fw-600">
                                                    <h3>13</h3>
                                                </div>
                                            </div>
                                            <div class="detail-block">
                                                <h4>{{tran.inteference}}</h4>
                                                    <p class="pt-2">{{tran.inteference_detail}}</p>
                                                <p class="pt-2">{{tran.inteference_a}}</p>
                                                <p class="pt-2">{{tran.inteference_b}}</p>
                                                <p class="pt-2">{{tran.inteference_c}}</p>
                                                <p class="pt-2">{{tran.inteference_d}}</p>

                                            </div>
                                            <div class="logo-block">
                                                <img src="../../../assets/images/req.png" height="30">
                                             </div>
                                            </div>

                                        </div>
                                        </div>
                                    </div>
    </Wrapper>
</div>
</template>

<script>
import Navbar from '../../partials/Navbar'
import Sidebar from '../../partials/Sidebar'
import Footer from '../../partials/Footer'
import Wrapper from '../../partials/Wrapper'
import trans from "../../../../translations";
import searchProduct from "../SearchProduct";
import http from "../../../http/index";
import {
    commonJs
} from '../mixins/commonJs.js';

import {
    mapState
} from "vuex";

export default {
    mixins: [commonJs],
    data() {

        return {
            showNavbar:true
        }
    },

    watch: {

    },
    methods: {

    },
    components: {
        Navbar,
        Footer,
        Sidebar,
        Wrapper,
        searchProduct
    },

    computed: {
        ...mapState({

            accountOverview: state => state.market.accountOverview,

        }),

    },
    mounted() {
        let heading = {

            main: this.tran.terms_condition_mob,
        }
        this.$store.commit('market/savePageHeading', heading)
        this.tran = trans;
            if (this.$route.query.src == 'mobile') {
    this.showNavbar = false
        }
    },
}
</script>

<style scoped>

</style>
